window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

// Page View
window.addEventListener('turbolinks:load', function(event) {
  if (window.ga_enabled) {
    gtag('config', window.analytics_id, {
      ...window.spree_user_id && { 'user_id': window.spree_user_id },
      'send_page_view': false,
      'allow_google_signals': true,
      'allow_ad_personalization_signals': true
    });
    gtag('config', window.analytics_id, {
      'page_location': event.data.url,
      'page_path': event.srcElement.location.pathname,
      'page_title': event.srcElement.title
    });
  }
})

export default gtag
